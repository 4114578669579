import React from 'react';
import{motion} from'framer-motion';
import {fadeIn} from '../variants';
import Img1 from '../assets/helathcare.png';
import Img2 from '../assets/game.png';
import Img3 from '../assets/portfolio.png';


const Work = () => {
  return <section className='section' id='work'>
    <div className='container mx-auto'>
      <div className='flex flex-col lg:flex-row gap-x-10'>
        <motion.div 
        variants={fadeIn('right',0.3)}
        initial="hidden"
        whileInView={'show'}
        viewport={{once:false,amount:0.3}}
        className='flex-1 flex flex-col gap-y-9 mb-10 lg:mb-0'>
                  <div>
                    <h2 className='he leading-tight text-accent'>
                      My Latest Projects
                      
                      </h2>
                      <p className='max-w-sm mb-16'>
                      I have led and contributed to various impactful web and mobile development projects, showcasing my skills in Full Stack Development, Game development, Mobile app creation, and UI/UX design, and consistently delivering successful and innovative solutions.</p>
                     <a href='https://www.linkedin.com/in/priyanshu-sekhar-nanda/'> <button className='btn btn-sm'>View All Projects</button></a>
                  </div>


                  <div className='group relative overflow-hidden border-2 border-white/50 rounded-1x'>

                    <div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
                    <img className='group-hover:scale-125 transition-all duration-500' src={Img1} alt=''></img>
                    <div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
                      <span className='text-gradient'>Android Development</span>
                    </div>
                    <div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-500 z-50'>
                      <span className='text-3xl text-white'>Good Life HealthCare App</span>
                      </div>


                  </div>
        </motion.div>
        <motion.div 
        variants={fadeIn('left',0.2)}
        initial="hidden"
        whileInView={'show'}
        viewport={{once:false,amount:0.3}}
        className='flex-1 flex flex-col gap-y-10'>


        <div className='group relative overflow-hidden border-2 border-white/50 rounded-1x'>

<div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
<img className='group-hover:scale-125 transition-all duration-500' src={Img2} alt=''></img>
<div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
  <span className='text-gradient'>Game Development</span>
</div>
<div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-500 z-50'>
  <span className='text-3xl text-white'>Magic Of LUDO</span>
  </div>


</div>

<div className='group relative overflow-hidden border-2 border-white/50 rounded-1x'>

<div className='group-hover:bg-black/70 w-full h-full absolute z-40 transition-all duration-300'></div>
<img className='group-hover:scale-125 transition-all duration-500' src={Img3} alt=''></img>
<div className='absolute -bottom-full left-12 group-hover:bottom-24 transition-all duration-500 z-50'>
  <span className='text-gradient'>Web Development</span>
</div>
<div className='absolute -bottom-full left-12 group-hover:bottom-14 transition-all duration-500 z-50'>
  <span className='text-3xl text-white'>Portfolio</span>
  </div>


</div>

        </motion.div>
      </div>
    </div>
    </section>;
};

export default Work;
