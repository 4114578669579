import React from 'react';
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { Link } from 'react-scroll';
import profileImage from '../assets/aboutYou.svg';

const About = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
  });

  return (
    <section className='section' id='about' ref={ref}>
      <div className="container mx-auto">
        <div className='flex flex-col gap-y-10 lg:flex-row lg:items-center lg:gap-x-20 lg:gap-y-0 h-screen '>
          <motion.div
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'
          >
            <img src={profileImage} alt="Profile" className=' object-cover' />
          </motion.div>
          <motion.div 
            variants={fadeIn('left', 0.5)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'
          >
            <h2 className='h2 text-accent'>About me</h2>
            <h3 className='h3 mb-4'>Hey there! I'm a Computer Science Engineer</h3>
            <p className='mb-6'> With a passion for AI and mobile app development. I specialize in Android development, blending technical expertise with creative design to craft engaging and user-friendly apps. Let's connect and bring innovative ideas to life together!</p>

            <div className='flex gap-x-6 lg:gap-x-10 mb-12'>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={3} duration={3}/> : null}
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Years of<br/>
                  Experience
                </div>
              </div>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={7} duration={3}/> : null}+
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Projects<br/>
                  Completed
                </div>
              </div>
              <div>
                <div className='text-[40px] font-tertiary text-gradient mb-2'>
                  {inView ? <CountUp start={0} end={21} duration={3}/> : null
                  }+
                </div>
                <div className='font-primary text-sm tracking-[2px]'>
                  Skills<br/>  
                </div>
              </div>
            </div>

            <div className='flex gap-x-8 items-center'>
              <Link 
                to='contact' 
                activeClass='active'
                smooth={true}
                spy={true} 
              >
                <button className='btn btn-sm'>
                  contact me
                </button>
              </Link>
              <a href='https://drive.google.com/file/d/1sVdNdhww-6kLbHJl1oRcoeCEeu84B0of/view?usp=drive_link' className='text-gradient btn-link'>
                My Resume
              </a>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
