import React from 'react';
import Image from '../assets/avatar.svg';
import { FaGithub, FaYoutube, FaDribbble, FaLinkedin, FaInstagram, FaFacebook } from 'react-icons/fa';
import { TypeAnimation } from 'react-type-animation';
import { motion } from "framer-motion";
import { Link } from 'react-scroll';
import { fadeIn } from '../variants';

const Banner = () => {
  return (
    <section className='min-h-[85vh] lg:min-h-[78vh] flex items-center' id='home'>
      <div className='container mx-auto'>
        <div className='flex flex-col gap-y-8 lg:flex-row lg:items-center lg:gap-x-12'>
          <div className='flex-1 text-center font-secondary lg:text-left'>
            <motion.h1
              variants={fadeIn('up', 0.3)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='text-[55px] font-bold leading-[0.8] lg:text-[90px]'
            >
              PRIYANSHU SEKHAR <span>NANDA</span>
            </motion.h1>

            <motion.div
              variants={fadeIn('up', 0.4)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='mb-6 text-[36px] lg:text-[55px] font-secondary font-semibold uppercase leading-[1]'
            >
              <span className='text-white mr-4'>I am a</span>
              <TypeAnimation
                sequence={[
                  'Web Developer', 2000,
                  'Android Developer', 2000,
                  'Game Developer', 2000,
                  'UI/UX Designer', 2000,
                ]}
                speed={50}
                className='text-accent'
                wrapper='span'
                repeat={Infinity}
              />
            </motion.div>

            <motion.p
              variants={fadeIn('up', 0.5)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='mb-8 max-w-lg mx-auto lg:mx-0'
            >
              Hi there! I'm a passionate Android developer and app designer dedicated to crafting seamless and intuitive mobile experiences. With a keen eye for detail and a love for innovative design.
            </motion.p>

            <motion.div
              variants={fadeIn('up', 0.6)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='flex max-w-max gap-x-6 items-center mb-12 mx-auto lg:mx-0'
            >
              <Link 
              to='contact' 
              activeClass='active'
              smooth={true}
              spy={true}
              
            >
              <button className='btn btn-sm'>
                contact me
              </button>
            </Link>
              <a href='https://drive.google.com/file/d/1sVdNdhww-6kLbHJl1oRcoeCEeu84B0of/view?usp=drive_link' className='text-gradient btn-link'>My Resume</a>
            </motion.div>

            <motion.div
              variants={fadeIn('up', 0.7)}
              initial="hidden"
              whileInView={'show'}
              viewport={{ once: false, amount: 0.7 }}
              className='flex text-[20px] gap-x-6 max-w-max mx-auto lg:mx-0'
            >
              <a href="https://www.linkedin.com/in/priyanshu-sekhar-nanda/">
                <FaLinkedin />
              </a>

              <a href="https://github.com/priyanshu18sekhar/">
                <FaGithub />
              </a>

              <a href="#">
                <FaInstagram />
              </a>

              <a href="https://www.facebook.com/profile.php?id=100091367627844/">
                <FaFacebook />
              </a>
            </motion.div>
          </div>

          <motion.div
            variants={fadeIn('down', 0.5)}
            initial="hidden"
            whileInView={'show'}
            className='hidden lg:flex flex-1 max-w-[320px] lg:max-w-[482px]'
          >
            <img src={Image} alt='Avatar' />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
