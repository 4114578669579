import React from 'react';
import { FaReact, FaHtml5, FaCss3Alt, FaJava, FaUnity, FaFigma, FaAndroid, FaCode, FaFileCode,FaTailw ,FaPython} from 'react-icons/fa';
import { SiJavascript, SiCsharp } from 'react-icons/si';
import { motion } from 'framer-motion';
import { fadeIn } from '../variants';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const services = [
  {
    name: 'Android Development',
    icons: [<FaAndroid key='android' />, <FaCode key='kotlin' />, <FaJava key='java' />, <FaFileCode key='xml' />],
    percentage: 87,
  },
  {
    name: 'Game Development',
    icons: [<FaUnity key='unity' />, <SiCsharp key='csharp' />,<FaJava key='java' />],
    percentage: 76,
  },
  {
    name: 'Web Development',
    icons: [<FaReact key='react' />, <FaHtml5 key='html5' />, <FaCss3Alt key='css3' />, <SiJavascript key='javascript' />,<FaPython key='python' />],
    percentage: 79,
  },
  {
    name: 'UI/UX Design',
    icons: [<FaFigma key='figma' />],
    percentage: 91,
  }
];

const Services = () => {
  return (
    <section className='section' id='service'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row'>
          <motion.div 
            variants={fadeIn('right', 0.3)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1 lg:bg-service lg:bg-bottom bg-no-repeat mix-blend-lighten mb-12 lg:mb-0'
          >
            <h2 className='h2 text-accent mb-6'><br/> <br/> <br/><br/><br/><br/><br/><br/>What I do</h2>
            <h3 className='h3 max-w-[455px] mb-16'>I'm a Computer Science and Engineer</h3>

            <a href='https://www.linkedin.com/in/priyanshu-sekhar-nanda/'><button className='btn btn-sm'>See My work</button></a>
          </motion.div>
          <motion.div 
            variants={fadeIn('left', 0.5)}
            initial="hidden"
            whileInView={'show'}
            viewport={{ once: false, amount: 0.3 }}
            className='flex-1'
          >
            <div>
              {services.map((service, index) => {
                const { name, icons, percentage } = service;
                return (
                  <div className='border-b border-white/20 h-[120px] mb-[38px] flex' key={index}>
                    <div className='max-w-[476px]'>
                      <h4 className='text-[20px] tracking-wider font-primary font-semibold mb-6'>{name}</h4>
                      <div className='flex gap-4 mb-4'>
                        {icons.map((icon, iconIndex) => (
                          <span key={iconIndex} className='text-3xl'>
                            {icon}
                          </span>
                        ))}
                      </div>
                      
                    </div>
                    <div className='flex flex-col flex-1 items-end h-25 w-25'>
                    <div className='flex flex-col flex-1 item-end h-20 w-20'>
                        <CircularProgressbar
                          value={percentage}
                          text={`${percentage}%`}
                          styles={buildStyles({
                            textSize: '24px',
                            pathColor: `url(#gradient${index})`,
                            textColor: 'white',
                            trailColor: '#d6d6d6',
                            fontWeight:'bold'
                          })}
                        />
                        <svg style={{ height: 0 }}>
                          <defs>
                            <linearGradient id={`gradient${index}`} gradientTransform="rotate(90)">
                              <stop offset="0%" stopColor="#FF6BBD" />
                              <stop offset="100%" stopColor="#0099FF" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Services;
