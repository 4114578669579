import React from 'react';
import logo from '../assets/logo.svg';
import { Link } from 'react-scroll';

const Header = () => {
  return (
    <div className='py-8'>
      <div className='container mx-auto'>
        <div className='flex justify-between items-center'>
          <a href='#'>
            <img src={logo} alt='Logo'></img>
          </a>
          <div>
            <Link 
              to='contact' 
              activeClass='active'
              smooth={true}
              spy={true}
              
            >
              <button className='btn btn-sm'>
                Work With Me
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
